import React, { useEffect } from "react"
import { setIsMap, setReadyToUse } from "../redux/actions/globalAction"
import { connect } from "react-redux"

import IndexPage from "./index"

const Annuaire = ({ dispatch, location }) => {
  useEffect(() => {
    dispatch(setIsMap(false))
    dispatch(setReadyToUse(true))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <IndexPage location={location} forceMap={false} />
}

export default connect()(Annuaire)
